<template>
  <div class="body">
    <div class="contain">
      <div class="_SelectHeader">
        <div class="_Select">
          <span>公司：</span>
          <el-select v-model="company" @change="togetDept" clearable placeholder="请选择">
            <el-option
              v-for="item in companyUserList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <el-button type="success" icon="el-icon-search" class="find" @click="searchData">搜索</el-button>
        <el-button type="primary" @click="drawQuestion">
          抽取题目
          <i class="el-icon-circle-plus-outline"></i>
        </el-button>
        <img
          v-show="new RegExp('股份公司管理员').test(roleName)"
          @click="deleteItem"
          src="../../assets/images/Examine_img/del.png"
        />
      </div>
      <div class="table">
        <el-table
          :data="tableData"
          style="width: 100%"
          :height="elementHeight"
          :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
          id="table"
          highlight-current-row
          :row-class-name="tableRowClassName"
          :row-style="selectedstyle"
          @row-dblclick="rowDblclick"
          @row-click="rowClick"
        >
          <el-table-column type="index" label="序号" width="70"></el-table-column>
          <el-table-column prop="companyName" label="被审公司" width="200"></el-table-column>
          <el-table-column prop label="部门" width="180">
            <template slot-scope="scope">
              <span>
                {{
                scope.row.deptName
                ? scope.row.deptName
                : scope.row.dutyDeptName
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="postName" label="岗位" width="180"></el-table-column>
          <el-table-column prop="titleCount" label="抽取问题数" width="120"></el-table-column>
          <el-table-column prop="failCount" label="问题点数" width="120"></el-table-column>
          <el-table-column prop="status" label="检查状态" width="120">
            <template slot-scope="scope">
              <span v-show="scope.row.status === 0">未抽题</span>
              <span v-show="scope.row.status === 1">已抽题</span>
              <span v-show="scope.row.status === 2">完成</span>
              <span v-show="scope.row.status === 3">整改完成</span>
              <span v-show="scope.row.status === 4">未完成</span>
            </template>
          </el-table-column>
          <el-table-column prop="saveTime" label="创建时间">
            <template slot-scope="scope">
              <span>{{ scope.row.saveTime ? scope.row.saveTime.split('T')[0] : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="saveUserName" label="创建人"></el-table-column>
        </el-table>
      </div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataCount"
        style="text-align: end; margin-top: 10px; margin-right: 10px"
      ></el-pagination>
    </div>
    <el-dialog title="抽取题目" :visible.sync="dialogVisible" width="1200px">
      <div class="dialogBox">
        <div class="leftBox">
          <div class="filtrate">
            <div style="margin-right: 10px">
              <span style="margin-right: 10px">所属部门</span>
              <el-select v-model="fileDept" clearable @change="togetPost" placeholder="请选择">
                <el-option
                  v-for="item in deptList"
                  :key="item.value"
                  :label="item.deptmentName"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <div style="margin-right: 10px">
              <span style="margin-right: 10px">抽取类型</span>
              <el-select v-model="extractionType" clearable placeholder="请选择" @change="typeChange">
                <el-option
                  v-for="item in extractionTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div v-show="extractionType == 1">
              <span>风控名称</span>
              <el-select
                v-model="riskName"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                :loading="loading"
                clearable
                @change="riskNameChange"
              >
                <el-option
                  v-for="item in riskOptions"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="tableBox">
            <el-table
              ref="multipleTable"
              :data="topicData"
              border
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column prop="fileName" label="文件名称"></el-table-column>
              <el-table-column prop="fileNo" label="文件编号" v-if="extractionType == 0"></el-table-column>
              <el-table-column prop="questionCount" label="总题数"></el-table-column>
              <el-table-column type="selection" width="55"></el-table-column>
            </el-table>
          </div>
          <div class="btnBox">
            <el-button class="btn" @click="confirm">确定选中</el-button>
          </div>
        </div>
        <div class="interval"></div>
        <div class="rightBox">
          <div class="filtrate">
            <span :style="{ lineHeight: extractionType == 1 ? '51px' : '30px' }">已选中</span>
          </div>
          <div class="tableBox">
            <el-table
              ref="checkedTable"
              :data="checkedData"
              border
              style="width: 100%"
              @selection-change="checkedSelectionChange"
            >
              <el-table-column label="抽题类型">
                <template slot-scope="scope">
                  <span v-if="scope.row.isFile">文件名称</span>
                  <span v-if="scope.row.isRisk">风控流程名称</span>
                </template>
              </el-table-column>
              <el-table-column prop="fileName" label="名称"></el-table-column>
              <el-table-column prop="fileNo" label="文件编号">
                <template slot-scope="scope">
                  <span v-if="scope.row.isFile">{{ scope.row.fileNo }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="questionCount" label="总题数"></el-table-column>
              <el-table-column label="检查方式">
                <template slot-scope="scope">
                  <span v-if="scope.row.isFile">检查内容</span>
                  <span v-if="scope.row.isRisk">风险点</span>
                </template>
              </el-table-column>
              <el-table-column type="selection" width="55"></el-table-column>
            </el-table>
          </div>
          <div class="btnBox">
            <el-button @click="toggleSelection">取消选中</el-button>
            <el-button class="btn" @click="extracting">确定抽题</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="检查方式" :visible.sync="open" width="400px" top="40vh">
      <div class="examineBox">
        <el-checkbox v-model="isRisk" @change="riskChange" :disabled="isFile">风险点</el-checkbox>
        <el-checkbox v-model="isFile" @change="fileChange" :disabled="isRisk">检查内容</el-checkbox>
      </div>
      <div class="examineBtnBox">
        <el-button class="btn" @click="affirm">&emsp;确认&emsp;</el-button>
        <el-button class="btn1" @click="cancel">取消操作</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, deleteMethod, post } from '../../api/http'
export default {
  data() {
    return {
      user: {},
      company: '',
      elementHeight: 0,
      tableData: [],
      tableDataCount: 1,
      dialogVisible: false,
      tableRowIndex: null,
      companyUserList: [],
      roleName: '',
      // 分页
      currentPage: 1,
      curSize: 10,
      count: 0,
      // 抽题
      isReq: false,
      deptList: [],
      fileDept: '',
      filePost: '',
      filePostList: [],
      titlelist: [],
      selectDrawVlue: [],
      fileAuditList: [],
      extractionType: null,
      extractionTypeList: [
        {
          label: '文件名称',
          value: '0',
        },
        {
          label: '风控流程名称',
          value: '1',
        },
      ],
      open: false,
      topicData: [],
      isRisk: false,
      isFile: false,
      disposeData: [],
      checkedData: [],
      resultData: [],
      riskName: '',
      riskOptions: [],
      loading: false,
    }
  },
  mounted() {
    this.getElementHeight()
    this.user = JSON.parse(sessionStorage.getItem('userInfo'))
    this.getCompanyList()
    this.roleName = JSON.parse(sessionStorage.getItem('userInfo')).roleName
    this.getTableList()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 130)
      })
    },

    getCompanyList() {
      get('/api/Company/GetAllNoPs').then((resp) => {
        if (resp.code == 200) {
          this.companyUserList = resp.data
        }
      })
    },

    // 搜索按钮
    searchData() {
      this.currentPage = 1
      this.getTableList()
    },

    handleSizeChange(e) {
      this.curSize = e
      this.getTableList()
    },

    handleCurrentChange(e) {
      this.currentPage = e
      this.getTableList()
    },

    // 点击表格
    rowClick(row) {
      this.tableRowIndex = row.index
      this.rowItem = row
    },

    rowDblclick(row) {
      this.$router.push('./risk-detail?riskItem=' + JSON.stringify(row))
    },

    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },

    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          'background-color': '#E2E8FA !important',
          'font-weight': 'bold !important',
          color: '#555555',
        }
      }
    },

    // 获取列表
    togetDept(e) {
      if (!e) return
      this.fileDept = ''
      this.filePost = ''
      this.getDeptList()
    },
    getDeptList() {
      get('/api/Department/GetNoTree?CompId=' + this.company).then((res) => {
        if (res.code == 200) {
          this.deptList = res.data
        }
      })
    },
    // 左边全选
    handleSelectionChange(val) {
      this.disposeData = val
    },
    // 右边全选
    checkedSelectionChange(val) {
      this.resultData = val
    },
    // 取消选中
    toggleSelection() {
      if (this.resultData.length == 0) {
        this.$message.warning('请选择要删除的数据')
        return
      }
      this.$confirm('此操作将删除该抽题, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.checkedData = this.checkedData.filter(
            (item1) => !this.resultData.some((item2) => item2 === item1)
          )
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    // 确定抽题
    extracting() {
      if (this.resultData.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择要抽取的题目',
        })
        return
      }
      post(
        '/api/RiskAudit/InsertRiskAudit?CompId=' +
          this.company +
          '&DeptId=' +
          this.fileDept,
        this.resultData
      ).then((res) => {
        if (res.code == 200) {
          this.$message.success('抽取成功')
          this.dialogVisible = true
          this.getTableList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    riskChange(e) {
      this.isRisk = e
      if (this.isRisk) {
        this.isFile = false
      } else {
        this.isFile = true
      }
    },
    fileChange(e) {
      this.isFile = e
      if (this.isFile) {
        this.isRisk = false
      } else {
        this.isRisk = true
      }
    },
    // 确定选中
    confirm() {
      this.open = true
      if (this.extractionType == 0) {
        this.isFile = true
        this.isRisk = false
      } else if (this.extractionType == 1) {
        this.isRisk = true
        this.isFile = false
      }
    },
    // 确认
    affirm() {
      if (this.disposeData.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择数据',
        })
        return
      }
      if (this.isFile) {
        this.disposeData.forEach((item) => {
          item.isFile = 1
          item.isRisk = 0
        })
      }
      if (this.isRisk) {
        this.disposeData.forEach((item) => {
          item.isFile = 0
          item.isRisk = 1
        })
      }
      this.checkedData = this.checkedData.concat(this.disposeData)
      // 出重数组
      this.checkedData = Array.from(
        new Set(this.checkedData.map(JSON.stringify))
      ).map(JSON.parse)
      this.open = false
      this.$refs.checkedTable.toggleAllSelection() // 选中表格数据全选
    },
    // 取消操作
    cancel() {
      this.open = false
      this.isFile = false
      this.isRisk = false
    },
    getTableList() {
      get(
        '/api/RiskAudit/GetByRiskAuditPage?CompId=' +
          this.company +
          '&DeptId=' +
          '&PostId=' +
          '&Status=&AuditType' +
          '&limit=' +
          this.curSize +
          '&page=' +
          this.currentPage
      ).then((resp) => {
        if (resp.code == 200) {
          if (this.dialogVisible) {
            this.dialogVisible = false
            this.getPage(resp.data[0])
            return
          }
          if (resp.count != 0 && resp.data.length == 0) {
            //解决删除页面最后一条数据时显示空白页面
            this.currentPage = this.currentPage - 1
            this.getTableList()
          } else {
            this.tableData = resp.data
            this.tableDataCount = resp.count
          }
        }
      })
    },
    // 获取题目
    // getTitleList() {
    //   this.titlelist = []
    //   get(
    //     '/api/RiskAudit/GetTitleList?CompId=' +
    //       this.company +
    //       '&DeptId=' +
    //       this.fileDept +
    //       '&PostId=' +
    //       (this.filePost ? this.filePost : 0) +
    //       '&FileAuditId=0'
    //   ).then((resp) => {
    //     if (resp.code == 200) {
    //       this.titlelist = resp.data
    //     }
    //   })
    // },

    drawQuestion() {
      if (this.company == '') {
        this.$message({
          type: 'warning',
          message: '未选择公司',
        })
        return
      }
      this.fileDept = ''
      this.extractionType = ''
      this.riskName = ''
      this.topicData = []
      this.checkedData = []
      this.riskOptions = []
      this.dialogVisible = true
      this.getDeptList()
    },

    // 确定抽题
    // drawReq() {
    //   if (this.selectDrawVlue.length == 0) {
    //     this.$message({
    //       type: 'warning',
    //       message: '请选择抽题题目',
    //     })
    //     return
    //   }
    //   post(
    //     '/api/RiskAudit/InsertRiskAudit?CompId=' +
    //       this.company +
    //       '&DeptId=' +
    //       this.fileDept +
    //       '&PostId=' +
    //       (this.filePost ? this.filePost : 0) +
    //       '&FileAuditId=0',
    //     this.selectDrawVlue
    //   ).then((resp) => {
    //     if (resp.code == 200) {
    //       this.$message({
    //         type: 'success',
    //         message: '抽题成功',
    //       })
    //       this.getTableList()
    //     }
    //   })
    // },
    getPage(e) {
      this.$router.push('./risk-detail?riskItem=' + JSON.stringify(e))
    },

    togetPost(e) {
      if (this.extractionType == 1 && e == '') {
        this.typeChange('1')
      }
      if (!e) return
      this.filePost = ''
      if (this.extractionType == 0 && this.extractionType != '') {
        this.typeChange(this.extractionType)
      } else if (this.extractionType == 1 && this.extractionType != '') {
        this.typeChange('1')
      }

      // this.getTitleList()
      // get('/api/Post?CompId=' + this.company + '&DeptId=' + e).then((res) => {
      //   if (res.code == 200) {
      //     this.filePostList = res.data
      //   }
      // })
    },

    //抽取类型
    typeChange(e) {
      this.extractionType = e
      if (this.extractionType == 0) {
        let data = {
          CompId: this.company,
          DeptId: this.fileDept,
          FileAuditId: this.extractionType,
        }
        get('/api/RiskAudit/GetTitleList', data).then((res) => {
          if (res.code == 200) {
            this.topicData = res.data
          } else {
            this.topicData = []
          }
        })
      } else {
        let data = {
          CompId: this.company,
          DeptId: this.fileDept ? this.fileDept : 0,
        }
        get('/api/RiskAudit/GetRiskListByComp', data).then((res) => {
          if (res.code == 200) {
            this.topicData = res.data
          } else {
            this.topicData = []
          }
        })
      }
    },

    // 风控名称模糊查询
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          get('/api/RiskAudit/GetRiskName?riskName=' + query).then((res) => {
            if (res.code == 200) {
              let list = []
              res.data.forEach((item) => {
                list.push({
                  label: item,
                })
              })
              this.riskOptions = list.filter((item) => {
                return (
                  item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
                )
              })
            }
          })
        }, 200)
      } else {
        this.riskOptions = []
      }
    },

    riskNameChange(e) {
      this.riskName = e
      let data = {
        CompId: this.company,
        DeptId: this.fileDept,
        riskName: this.riskName,
      }
      get('/api/RiskAudit/GetTitleListRiskName', data).then((res) => {
        if (res.code == 200) {
          this.topicData = res.data
        } else {
          // this.topicData = []
          this.typeChange('1')
        }
      })
    },

    // changePost(e) {
    //   this.filePost = e
    //   this.getTitleList()
    // },

    // selectionDraw(e) {
    //   this.selectDrawVlue = e
    // },

    // 删除
    deleteItem() {
      if (this.tableRowIndex == null) {
        this.$message('未选中列表')
        return
      }
      this.$confirm('此操作将永久删除该检查', '是否继续?', '提示')
        .then(() => {
          deleteMethod('/api/RiskAudit?id=' + this.rowItem.id)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
                this.tableRowIndex = null
                this.getTableList()
              } else {
                this.$message.error(res.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../assets/style/Examine_CSS.css';
.body {
  background-color: #e7e9ec;
  height: 100vh;
}
._SelectHeader {
  margin-bottom: 1px;
  img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }
}
.table,
.pagination {
  background-color: white;
}
.pagination {
  text-align: end;
  padding: 10px 5px;
}
.contain {
  padding: 0 10px;
  margin-top: 5px;
}
.table {
  padding: 10px 10px 0 10px;
}

.draw-contain {
  width: 100%;
  max-height: 480px;
  overflow-y: scroll;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #dbdbdb;
}
/deep/.el-dialog__body {
  padding: 20px !important;
}
.dialog-footer {
  text-align: center !important;
}
/deep/ .el-select__tags {
  flex-wrap: nowrap !important;
  overflow: hidden;
}
/deep/ .el-date-editor.el-input {
  width: 200px !important;
}
/deep/ .el-input-number__decrease,
/deep/ .el-input-number__increase {
  background: none !important;
}

.dialogBox {
  display: flex;
  .leftBox {
    width: calc(50% - 2.5px);
    padding-right: 14px;
    box-sizing: border-box;
  }
  .filtrate {
    display: flex;
    margin-bottom: 8px;
    & > div {
      span {
        display: inline-block;
        margin-bottom: 5px;
      }
    }
  }
  .interval {
    width: 5px;
    background: #f6f6f6;
  }
  .rightBox {
    width: calc(50% - 2.5px);
    padding-left: 22px;
    box-sizing: border-box;
  }
  .tableBox {
    height: 400px;
    background: #f5f5f5;
    overflow-y: auto;
  }
  .btnBox {
    width: 100%;
    text-align: right;
    margin-top: 18px;
    .btn {
      background-color: #4274c0;
      color: #ffffff;
    }
  }
}
.examineBox {
  text-align: center;
}
.examineBtnBox {
  width: 100%;
  text-align: center;
  margin-top: 66px;
  .btn {
    background-color: #4274c0;
    color: #ffffff;
    margin-right: 30px;
  }
  .btn1 {
    margin-left: 30px;
  }
}
</style>
